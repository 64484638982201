import React, { useState } from "react";
import icons from "../icons/icons";
import "./Modal.css"; // Ensure your custom styles are defined here

const StarBtnModal = () => {
  const [show, setShow] = useState(false);
  const [days, setDays] = useState(100);
  const [stars, setStars] = useState(200); // Initial stars based on the initial days
  const [isConfirmed, setIsConfirmed] = useState(false); // State to track if the button is clicked

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const incrementDays = () => {
    setDays((prevDays) => {
      const newDays = prevDays + 100; // Increment days by 100
      setStars(newDays * 2); // Calculate stars based on the new days value
      return newDays;
    });
  };

  const decrementDays = () => {
    setDays((prevDays) => {
      const newDays = Math.max(100, prevDays - 100); // Decrement days by 100 but not below 100
      setStars(newDays * 2); // Calculate stars based on the new days value
      return newDays;
    });
  };

  const handleConfirm = () => {
    setIsConfirmed(true); // Set confirmed state to true
  };

  // Handle click outside of modal content
  const handleOverlayClick = (event) => {
    if (event.target.className.includes("modal show")) {
      handleClose();
    }
  };

  return (
    <>
      <button className="star-btn" onClick={handleShow}>
        <span>Буст за</span> Telegram Stars
        <img src={icons.star} alt="Star" />
      </button>

      <div
        className={`modal ${show ? "show" : ""}`}
        style={{ display: show ? "block" : "none" }}
        onClick={handleOverlayClick} // Register the click handler here
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          {" "}
          {/* Stop click event from bubbling up */}
          <div className="modal-header">
            <h5 className="modal-title">
              Буст за Telegram Stars
              <img src={icons.star} alt="Star" />
            </h5>
          </div>
          <div className="modal-body">
            {!isConfirmed ? (
              <>
                <p>Обменяйте Telegram Stars за выполненные задания по дням.</p>
                <div className="d-flex align-items-center justify-content-center">
                  <button className="btn-control" onClick={decrementDays}>
                    —
                  </button>
                  <span className="days">{days} дней</span>
                  <button className="btn-control" onClick={incrementDays}>
                    +
                  </button>
                </div>
              </>
            ) : (
              <p>
                Обменяйте Telegram Stars за приглашение друзей и подключите
                кошелёк.
              </p>
            )}
          </div>
          <div className="modal-footer">
            {!isConfirmed ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleConfirm}
              >
                Обменять за {stars} Stars
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Обменять за {stars} Stars
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StarBtnModal;
